@font-face {
    font-family: 'thai_sans_literegular';
    font-weight: normal;
    font-style: normal;

    src: url('/fonts/thaisanslite_r1-webfont.woff2') format('woff2'),
    url('/fonts/thaisanslite_r1-webfont.woff') format('woff');
}

html, body {
    font-family: 'thai_sans_literegular', sans-serif;
    font-size: 18px;
}

h1, h2, h3, h4, h5, h6 {
    padding: 5px 0px;
    margin: 0;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 23px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

a {
    -webkit-transition: .5s;
    transition: .5s;
    text-decoration: none;
    user-select: none;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clr {
    clear: both;
}

.error-title {
    display: block;
    color: #ababab;
    font-size: 60px;
    margin: 50px auto;
    text-align: center;
    width: 80%;
}

.tr-checkbox {
    cursor: pointer;
}

.equal .panel {
    position: relative;
}

.equal .panel .panel-body {
    margin-bottom: 50px;
}

.equal .panel .panel-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.modal {
    z-index: 990;
}
.modal-backdrop {
    z-index: 980;
}

.box {
    border: 1px solid #eee;
    padding: 25px 20px 10px 20px;
    margin: auto 5px 30px 5px;
    position: relative;
}

.box .box-title {
    background: #fff;
    font-size: 24px;
    padding: 0 20px;
    position: absolute;
    top: -31px;
    left: 10px;
    font-weight: normal;
    line-height: 24px;
}

/* Bootstrap Modifies */
.page-header {
    margin: 10px 0 20px 0;
}

#product dl.dl-horizontal dt,
#product dl.dl-horizontal dd {
    padding: 3px 0;
}

.list-group-lg-icon {
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -15px;
}

/* Top Menu */
.top-menu {
    background-color: #0081c0;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    min-height: 50px;
}

.top-menu.only-sub-menu {
    min-height: 40px;
    margin: 0 -15px;
    width: calc(100% + 30px);;
    top: -21px;
    position: relative;
}

.top-menu.only-sub-menu .menu > li > ul {
    display: block;
}

.top-menu .menu-logo {
    float: left;
    margin: 13px 10px 10px 20px
}

.top-menu .menu-logo img {
    margin-top: -10px;
    margin-bottom: -10px;
}
.top-menu .menu-title {
    border-right: 2px solid rgba(255, 255, 255, 0.8);
    float: left;
    margin: 0;
    padding: 15px 20px 15px 15px;
}

.top-menu .menu-title > a {
    color: #E6E6E6;
}

.top-menu .menu-title > a:hover {
    color: #fff;
    text-decoration: none;
}

.top-menu .menu-hamburger {
    display: none;
    cursor: pointer;
    float: right;
    font-size: 30px;
    position: relative;
    top: 5px;
    right: 8px;
    z-index: 10000;
}

.top-menu .menu-hamburger > span {
    position: absolute;
    top: 5px;
    right: 5px;
}

.top-menu .menu-hamburger .menu-close {
    display: none;
}

.top-menu .menu-hamburger.float {
    background-color: #337ab7;
    border-radius: 5px;
    position: fixed;
    top: 5px;
    right: 3px;
    width: 40px;
    height: 40px;
}

.top-menu .menu-left {
    float: left;
}

.top-menu .menu-right {
    float: right;
}

.top-menu .menu {
    margin-bottom: 0;
    padding: 0;
}

.top-menu .menu li {
    float: left;
    list-style: none;
}

.top-menu .menu li.open {
    margin-bottom: 40px;
}

.top-menu .menu li.no-child {
    margin-bottom: 0;
}

.top-menu .menu > li a,
.top-menu .menu > li a:visited {
    display: block;
    color: #fff;
    padding: 10px 10px;
    vertical-align: middle;
    text-decoration: none;
}

.top-menu .menu > li a:hover,
.top-menu .menu > li.active a,
.top-menu .menu > li.open a {
    background-color: #006eab;
    color: #fff;
    text-decoration: none;
}

.top-menu .menu > li > ul {
    display: none;
    background-color: #006eab;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;
}

.top-menu .menu > li.open > ul {
    display: block;
}

.top-menu .menu > li > ul li {
    float: left;
}

.top-menu .menu > li > ul li a {
    padding: 6px 15px;
}

.top-menu .menu > li > ul li a:hover,
.top-menu .menu > li > ul li.active a {
    background-color: #fff;
    color: #000;
}

.top-menu .menu > li > ul.menu-dropdown {
    left: inherit;
    right: inherit;
    z-index: 999;
    -webkit-transition: 2s;
    transition: 2s;
}

.top-menu .menu > li:hover > ul.menu-dropdown {
    display: block;
    width: auto;
}

.top-menu .menu > li > ul.menu-dropdown li {
    display: block;
    width: 100%;
}

.top-menu li.divider {
    border: 1px solid #fff;
}

.top-menu .menu-content {
    margin-bottom: 3rem;
}

@media (max-width: 1000px) {
    /*body { overflow-x: hidden; }*/
    .top-menu .menu-hamburger {
        display: block;
    }

    .top-menu .menu-title {
        border-right: 0px;
    }

    .top-menu .menu-content {
        background-color: #000;
        position: fixed;
        right: -60%;
        overflow: scroll;
        width: 60%;
        height: 100%;
        min-height: 100%;
        z-index: 9999;
    }

    .top-menu .menu {
        border-bottom: 2px solid #fff;
        float: none;
        position: static;
        width: 100%;
    }

    .top-menu .menu > li {
        display: block;
        float: none;
    }

    .top-menu .menu > li > a,
    .top-menu .menu > li > a:visited {
        background-color: #337ab7;
        display: block;
        color: #fff;
        padding: 10px 10px;
        vertical-align: middle;
        text-decoration: none;
        width: 100%;
    }

    .top-menu .menu > li.active > a,
    .top-menu .menu > li > a:hover {
        background-color: #284965;
        color: #fff;
    }

    .top-menu .menu > li > a:hover > ul li a {
        background-color: #337ab7;
    }

    .top-menu .menu > li > ul {
        display: block;
        position: static;
        width: 100%;
    }

    .top-menu .menu > li > ul li {
        width: 100%;
    }

    .top-menu .menu > li.open > ul li a,
    .top-menu .menu > li > ul li a {
        background-color: #337ab7;
        padding: 15px 15px 15px 30px;
    }

    .top-menu .menu > li > ul li.active a,
    .top-menu .menu > li > ul li a:hover {
        background-color: #284965;
        color: #fff;
    }

    .top-menu .menu > li > ul.menu-dropdown {
        border: 0;
    }
}

.btn-manage-group {
    padding: 20px 0;
}

.item-error {
    border: 1px solid #e35051;
    padding: 10px;
}

.item-new {
    border: 1px solid #57c0e8;
    padding: 10px;
    margin: 20px 0;
}

.item-complete {
    border: 1px solid #61c161;
    padding: 10px;
}

/* Bootstrap - Modifies */
.badge.notify-number {
    background-color: #ff9200;
    font-size: 10px;
    margin: 0 0 0 10px;
    padding: 3px;
    position: absolute;
    z-index: 1;
}

.panel-heading {
    background-color: #0081c9;
}

.panel-heading h5 {
    font-weight: bold;
}

.panel-primary {
    border-color: #f1f1f1;
}

.panel-title {
    font-size: 20px;
}

.input-group.input-group-search .form-control {
    float: right;
    width: 60%;
}

#setting-page .input-group-addon {
    float: none;
}

.form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
}

/* title form */
.box-title {
    font-weight: 600;
    margin-top: 15px;
}

label {
    font-weight: 500;
}

.form-control {
    font-size: 18px;
    color: #5e5e5e;
}

textarea {
    font-family: sans-serif;
    font-size: 14px !important;
}

select option:disabled {
    background-color: #ccc;
}

.input-group .form-control {
    float: left;
}

.btn {
    padding: 4px 12px;
    font-size: 17px;
    font-weight: lighter;
}

.btn-xs {
    padding: 0 6px;
}

.input-group .btn {
    padding: 4px 12px;
}

.pagination li a, .pagination li span {
    line-height: 1.1;
}

.list-group-item, .nav>li>a {
    line-height: 1.1;
}

/* Fix table style */
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 6px 10px;
    line-height: 1.5;
}
.table thead tr th {
    border-bottom: 2px solid #337ab7;
}
.table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
}
.table tbody tr:hover {
    background-color: #f0f0f0;
}
.table > tbody > tr > td {
    vertical-align: middle;
}

td label {
    margin-bottom: 0;
}

tbody {
    border-bottom: 1px solid #ddd;
}

.panel-primary .panel-heading small {
    color: #fff;
}

.panel-body .table {
    margin: -15px -15px 0;
    width: calc(100% + 30px) !important;
    max-width: none;
}
.panel-body .table tr th:first-child, .panel-body .table tr td:first-child {
    padding-left: 30px;
}
.panel-body .table tr th:last-child, .panel-body .table tr td:last-child {
    padding-right: 30px;
}
.panel-body .pagination {
    margin-bottom: 0;
}

.table tbody tr.success td {
    border-top: 1px solid #d6e9c6;
    border-bottom: 1px solid #d6e9c6;
}

.table tbody tr.info td {
    border-top: 1px solid #bce8f1;
    border-bottom: 1px solid #bce8f1;
}

.table tbody tr.warning td {
    border-top: 1px solid #faebcc;
    border-bottom: 1px solid #faebcc;
}

.table tbody tr.danger td {
    border-top: 1px solid #ebccd1;
    border-bottom: 1px solid #ebccd1;
}

.cart-order-detail:not(:last-child) {
    border-bottom: 1px solid #eee; margin-bottom: 15px
}
