/* css sidebar form https://bootsnipp.com/snippets/featured/sexy-sidebar-navigation */

.nav-sidebar {
    width: auto;
    margin: 8px -15px 8px 0;
    padding-right: 15px;
    border-right: 1px solid #ddd;
    transition: all 0.2s ease;
}

.nav-sidebar a {
    color: #333;
    -webkit-transition: all 0.08s linear;
    -moz-transition: all 0.08s linear;
    -o-transition: all 0.08s linear;
    transition: all 0.08s linear;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.nav-sidebar .active a {
    cursor: default;
    background-color: #428bca !important;
    color: #fff;
    text-shadow: 1px 1px 1px #666;
}

.nav-sidebar .active a:hover {
    background-color: #428bca;
}

.nav-sidebar .changed a {
    color: #f00;
    background-color: #ffe0e0;
}

.nav-sidebar .text-overflow a,
.nav-sidebar .text-overflow .media-body {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

/* Right-aligned sidebar */
.nav-sidebar.pull-right {
    border-right: 0;
    border-left: 1px solid #ddd;
}

.nav-sidebar.pull-right a {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

/* Sidebar sticky on scroll */
.nav-sidebar.sticky {
    position: fixed;
    top: 100px;
    left: 0;
    background: #fff;
    padding-top: 0px;
    margin: auto;
    width: 300px;
    /*height: calc(100vh - 200px);*/
    border-right: 1px solid #ddd;
}

.tab-page-group .tab-page {
    display: none;
}

