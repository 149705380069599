/** PRELOADER by http://cssload.net/en/spinners **/
.preloader {
    background: #fff;
    padding-top: 10%;
    position: fixed;
    right: 0;
    top: 0;
    text-align: center;
    width: 100%;
    min-height: 100%;
    z-index: 1000;
    display: none;
}

.cssload-square {
    margin: 96px auto;
    width: 28px;
    height: 28px;
    transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
}

.cssload-square-part {
    position: absolute;
    width: 28px;
    height: 28px;
    z-index: 1;
    animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
    -o-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
    -ms-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
    -webkit-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
    -moz-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
}

.cssload-square-green {
    background: rgb(87, 191, 232);
    right: 0;
    bottom: 0;
    animation-direction: alternate-reverse;
    -o-animation-direction: alternate-reverse;
    -ms-animation-direction: alternate-reverse;
    -webkit-animation-direction: alternate-reverse;
    -moz-animation-direction: alternate-reverse;
}

.cssload-square-pink {
    background: rgb(0, 131, 201);
    left: 0;
    top: 0;
}

.cssload-square-blend {
    background: rgb(7, 60, 101);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    animation: blend-anim 1.04s ease-in infinite;
    -o-animation: blend-anim 1.04s ease-in infinite;
    -ms-animation: blend-anim 1.04s ease-in infinite;
    -webkit-animation: blend-anim 1.04s ease-in infinite;
    -moz-animation: blend-anim 1.04s ease-in infinite;
}

@keyframes blend-anim {
    0% {
        transform: scale(0.01, 0.01) rotateY(0);
        animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    50% {
        transform: scale(1, 1) rotateY(0);
        animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    100% {
        transform: scale(0.01, 0.01) rotateY(0);
    }
}

@-o-keyframes blend-anim {
    0% {
        -o-transform: scale(0.01, 0.01) rotateY(0);
        -o-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    50% {
        -o-transform: scale(1, 1) rotateY(0);
        -o-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    100% {
        -o-transform: scale(0.01, 0.01) rotateY(0);
    }
}

@-ms-keyframes blend-anim {
    0% {
        -ms-transform: scale(0.01, 0.01) rotateY(0);
        -ms-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    50% {
        -ms-transform: scale(1, 1) rotateY(0);
        -ms-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    100% {
        -ms-transform: scale(0.01, 0.01) rotateY(0);
    }
}

@-webkit-keyframes blend-anim {
    0% {
        -webkit-transform: scale(0.01, 0.01) rotateY(0);
        -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    50% {
        -webkit-transform: scale(1, 1) rotateY(0);
        -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    100% {
        -webkit-transform: scale(0.01, 0.01) rotateY(0);
    }
}

@-moz-keyframes blend-anim {
    0% {
        -moz-transform: scale(0.01, 0.01) rotateY(0);
        -moz-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    50% {
        -moz-transform: scale(1, 1) rotateY(0);
        -moz-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    100% {
        -moz-transform: scale(0.01, 0.01) rotateY(0);
    }
}

@keyframes cssload-part-anim {
    0% {
        transform: translate3d(-14px, -14px, 0);
    }
    100% {
        transform: translate3d(14px, 14px, 0);
    }
}

@-o-keyframes cssload-part-anim {
    0% {
        -o-transform: translate3d(-14px, -14px, 0);
    }
    100% {
        -o-transform: translate3d(14px, 14px, 0);
    }
}

@-ms-keyframes cssload-part-anim {
    0% {
        -ms-transform: translate3d(-14px, -14px, 0);
    }
    100% {
        -ms-transform: translate3d(14px, 14px, 0);
    }
}

@-webkit-keyframes cssload-part-anim {
    0% {
        -webkit-transform: translate3d(-14px, -14px, 0);
    }
    100% {
        -webkit-transform: translate3d(14px, 14px, 0);
    }
}

@-moz-keyframes cssload-part-anim {
    0% {
        -moz-transform: translate3d(-14px, -14px, 0);
    }
    100% {
        -moz-transform: translate3d(14px, 14px, 0);
    }
}