
tbody tr.checked {
    background: #fcf8e3 !important;
    border: 1px solid #faebcc;
}

tbody tr.checked.new {
    background: #e0f0ff !important;
    border: 1px solid #a6d4ff;
}

tbody tr.checked td {
    border: none !important;
}

tbody tr.checked + tr td {
    border-top: none !important;
}

#product-edit-search-list .activate {
    background-color: #3bdaac;
    color: #fff;
}

#product-edit-search-list .active.activate {
    background-color: #337ab7;
    border-color: #337ab7;
}

#product-edit .tab-pane h1, #kbank-registration-editor h1 {
    margin: 25px 0;
    text-align: center;
}

#product-edit .table {
    margin: 0 -15px 25px;
    width: calc(100% + 30px) !important;
}

#product-edit .table tr th:first-child, #product-edit .table tr td:first-child {
    padding-left: 15px;
}
#product-edit .table tr th:last-child, #product-edit .table tr td:last-child {
    padding-right: 15px;
}

#product-edit h5 {
    margin-bottom: 10px;
}

#product-edit #save {
    margin-top: 20px;
    text-align: center;
}

#product-edit #save button#reset-bt {
    min-width: 70px;
}

#product-edit #save button#save-bt {
    min-width: 100px;
}

#product-edit .td-barcode {
    text-align: center;
}

#product-edit .td-barcode div {
    height: 0;
    overflow: visible;
}

#product-edit .td-barcode div svg {
    position: relative;
    top: -4px;
    left: -5px;
}

.images-list {
    margin-top: 20px;
}

.images-list .card {
    margin-bottom: 10px;
    position: relative;
}

.images-list .card.checked {
    background: #fcf8e3 !important;
}

.images-list .card .inner {
    width: 300px;
    height: 300px;
    line-height: 295px;
    text-align: center;
    background-color: #eee;
}

.images-list .card .bt {
    display: none;
    position: absolute;
    background-color: rgba(255, 255, 255, .8);
    width: 25px;
    height: 25px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
}

.images-list .card:hover .bt {
    display: block;
}

.images-list .card .delete-bt {
    top: 10px;
    right: 10px;
    color: red;
}

.images-list .card .up-bt {
    top: 138px;
    left: 10px;
}

.images-list .card .down-bt {
    top: 138px;
    right: 10px;
}


